import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { ServiceService } from 'src/app/service.service';
declare var $: any;

@Component({
    selector: 'app-shop-full-width-page-one',
    templateUrl: './shop-full-width-page-one.component.html',
    styleUrls: ['./shop-full-width-page-one.component.scss']
})
export class ShopFullWidthPageOneComponent implements OnInit {
    productsLists:any;
    filterdProductLists:any;
    productDetails:any;
    uniqBrands:any;
    subscriber: Subscription;
    public _filter:boolean= false;
    public isMobile:boolean= false;
    public isTablet:boolean= false;
    public loader:boolean= false;
    public type:any= '';
    public posterImageUrl:any= '';
    public usdzUrl:any= '';
    public glburl:any= '';
    customOptionsHome: OwlOptions = {
        loop: true,
        autoplay: true,
        center: true,
        dots: true,
        autoHeight: true,
        autoWidth: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      };
    constructor(private service : ServiceService, private deviceService: DeviceDetectorService,private router: Router) { 
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
    }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
        this.getProducts();
        this.service.type.subscribe((res)=>{
            if(res){
                this.type = res;
            }
        });
        this.subscriber = this.service.filter.subscribe((res)=>{
            if(res == 'reset'){
                this.service.resetFilter.next('reset');
                return;
            }
            if(res) {
                this.getSelectedItem(res);
                this._filter = true;
                // this.filterdProductLists = this.productsLists.filter((item) => item.brand == res);
            } else {
                this._filter = false;
                // this.filterdProductLists = this.productsLists;
                this.getProducts();
            }
        })
    }
    /**
     * getProducts function to get product lists
     */
    getProducts(){
        this.loader = true;
        this.service.getData('product_list').subscribe((res)=>{
            this.loader = false;
            this.productsLists = res?.data;
            this.filterdProductLists = res?.data;
            this.uniqBrands = [...new Map(this.productsLists.map(item => [item['brand'], item])).values()];
            this.uniqBrands.forEach(element => {
                let item = {};
                item['val'] = this.productsLists.filter((item)=>item.brand == element.brand);
                item['keys'] = element.brand
                this.service.itemByBrandCategory.push(item);
            });
        })
    }
    /**
     * getSelectedItem function to get data from selected category/brands items
     * @param item selected items
     */
    getSelectedItem(item:any){
        let url:any;
        this.loader = true;
        if(this.type == 'categories'){
            url = "filter/tags";
            let body = [item];
            this.service.postFilterData(url, body).subscribe((res)=>{
                let data = JSON.parse(JSON.stringify(res));
                this.productsLists = data?.data;
                this.filterdProductLists = data?.data;
                this.loader = false;
            })
        } 
        if(this.type == 'brands'){
            url = `filter/brand?brand=${item}`;
            this.service.getData(url).subscribe((res)=>{
                this.productsLists = res?.data;
                this.filterdProductLists = res?.data;
                this.loader = false;
            });
        }
    }
/**
 * reset filter from home page 
 */
    resetFiletr(){
        this.service.resetFilter.next('reset');
        this._filter = false;
    }
    getProductDetails(pId){
        // if(this.isMobile || this.isTablet){

        //     this.loader = true;
        //     this.posterImageUrl = '';
        //     this.usdzUrl = '';
        //     this.glburl = '';
        //     this.service.getData('product_details?productID=' + pId).subscribe((res)=>{
        //         if(res){
        //             this.productDetails = res;
        //             this.posterImageUrl = res.imageURL;
        //             // this.usdzUrl = data.usdzURL + "?modelscale=20";
        //             this.usdzUrl = res.usdzURL;
        //             this.glburl = res.gltfURL;
        //             setTimeout(()=>{
        //                 this.loader = false;
        //                 $("#productsQuickViewMobile").modal('show');
        //             }, 100);
        //         }
        //     })
        // } else {
            this.router.navigate([pId]);
        // }
        
        
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'ARnxt Your own place'
        }
    ]
    // For Pagination
    shopFullWidth: number = 1;

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    options = [
        {
            name: "Default",
        },
        {
            name: "Popularity",
        },
        {
            name: "Latest",
        },
        {
            name: "Price: low to high",
        },
        {
            name: "Price: high to low",
        }
    ];
    searchChange($event) {
        // console.log($event);
    }
    reset() {
        this.resetOption = [];
    }
    ngOnDestroy(): void{
        if(this.subscriber) this.subscriber.unsubscribe();
    }

}