import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-model-viewer',
  templateUrl: './model-viewer.component.html',
  styleUrls: ['./model-viewer.component.scss']
})
export class ModelViewerComponent {
  public isMobile:boolean= false;
  public isTablet:boolean= false;
  public posterImageUrl:any= '';
  public usdzUrl:any= '';
  public glburl:any= '';
  constructor(private deviceService: DeviceDetectorService, private activatedRoute:ActivatedRoute, 
    private router: Router) { 
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
}
  ngOnInit(): void {
    this.activatedRoute.data.subscribe((item) => {
      let data = item.data;
      this.posterImageUrl = data.imageURL;
      // this.usdzUrl = data.usdzURL + "?modelscale=20";
      this.usdzUrl = data.usdzURL;
      this.glburl = data.gltfURL;
    })
}
}
