import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ServiceService } from 'src/app/service.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class FooterComponent implements OnInit {

    location: any;
    bgClass: any;
    isValue: any;
    filterCategoryLists: any;
    filterBrandLists: any;
    filterApplied:boolean = false;
    itemByBrandCategory:any =[];

    constructor(
        private router: Router,
        location: Location,
        private service:ServiceService
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/' || this.location == '/index-2' || this.location == '/index-3' || this.location == '/about'){
                    this.bgClass = '';
                }  else {
                    this.bgClass = 'bg-f5f5f5';
                }
            }
        });
    }

    ngOnInit(): void {
        this.itemByBrandCategory = this.service.itemByBrandCategory;
        this.getAllCategory();
        this.getAllBrands();
        this.service.resetFilter.subscribe((res)=>{
            this.itemByBrandCategory = this.service.itemByBrandCategory;
            if(res = 'reset'){
                this.resetFilter();
            }
        })
    }
/**
 * getAllCategory function to get all categories filter lists
 */
    getAllCategory(){
        let url = "filter/all_cats"
        this.service.getData(url).subscribe((res)=>{
          this.filterCategoryLists = (res?.allTags).sort();
          this.filterCategoryLists = this.filterCategoryLists.filter(item => item);
        })
      }
/**
 * getAllBrands function to get all Brands filter lists
 */
      getAllBrands(){
        let url = "filter/allbrands"
        this.service.getData(url).subscribe((res)=>{
          this.filterBrandLists = (res?.allbrands).sort();
        })
      }
/**
 * applyFilter
 * @param $event 
 * @param selectedItem 
 * @param type 
 */
    applyFilter($event, selectedItem:string, type:string='') {
        this.service.type.next(type);
        this.service.filter.next(selectedItem);
        this.isValue = selectedItem;
        this.filterApplied = true;
    }
/**
 * resetFilter to reset filter
 */
    resetFilter(){
        this.service.type.next(null);
        this.service.filter.next(null);
        this.isValue = null;
        this.filterApplied = false;
    }
}