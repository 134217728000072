<!-- Start Footer Area -->
<footer class="footer-area pt-100 {{bgClass}}" id="footer" #footer>
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-xs-6 col-sm-4 offset-xs-3 col-md-4">
                <div class="single-footer-widget company_logo">
                    <a routerLink="/" class="logo d-inline-block">
                        <img src="assets/images/quleeplogomain.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8 col-xs-12 col-md-8">
                <div class="single-footer-widget pr-5">
                    <h3>Company</h3>
                    <p>
                        Quleep is a leading innovation-driven technology company having the advantage of building next-generation technology products / services for modern industries, enterprises, and businesses. Quleep specialises in developing B2B2C Augmented reality product for business across the Globe. We are expert in creating immersive and outstanding visual delight that intensify human interaction through emerging technologies. Lets help you to create experiential solutions to accelerate business with customer delight.
                    </p>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/ARNXT/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ARNXT1" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/arnxt" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/arnxt2021/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-contact-info">
                        <li>Noida - Serenia, IHDP Business Park, Sector 127, Noida-201303.</li>
                        <li></li>
                        <li>Kolkata - 11th floor Kamdhenu Building 75C, Park Street, Kolkata-700016.</li>
                        <li>Email: <a href="mailto:care@arnxt.com">care@arnxt.com</a></li>
                        <li>Phone: <a href="tel:+919883019518">+91 9883019518</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>© 2023, Quleep. All Right Reserved</p>
                </div>

                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/customer-service">Privacy Policy</a></li>
                        <li><a routerLink="/customer-service">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Sidebar Modal Area -->
<div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal"><i class='bx bx-x'></i></button>
            <div class="modal-body">
                <div class="logo">
                    <a href="index.html" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
                </div>
                <div class="instagram-list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products5.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products6.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products7.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products8.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products9.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="box">
                                <img src="assets/img/products/products10.jpg" alt="image">
                                <i class='bx bxl-instagram'></i>
                                <a href="#" target="_blank" class="link-btn"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-contact-info">
                    <h2>
                        <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        <span>OR</span>
                        <a href="mailto:hello@fiwan.com">hello@fiwan.com</a>
                    </h2>
                </div>
                <ul class="social-list">
                    <li><span>Follow Us On:</span></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-twitch'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal Area -->

<!-- Start QuickView Modal Area -->
<div class="modal fade productsQuickView" id="productsQuickView" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="products-image">
                        <img src="assets/img/products/products1.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="products-content">
                        <h3>Rivet Farr Lotus</h3>
                        <div class="price">
                            <span class="new-price">$150.00</span>
                        </div>
                        <div class="products-review">
                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <a href="#" class="rating-count">3 reviews</a>
                        </div>
                        <p>Nemo malesuada animi consectetur, cras consectetuer laborum tenetur, cum, lacus nemo imperdiet facilisis! Aute metus, lorem primis anim. Eros dolorem.</p>
                        <ul class="products-info">
                            <li><span>Vendor:</span> <a href="#">Lereve</a></li>
                            <li><span>Availability:</span> <a href="#">In stock (7 items)</a></li>
                            <li><span>Products Type:</span> <a href="#">Chair</a></li>
                            <li><span>Categories:</span> <a href="#">Chair</a></li>
                        </ul>
                        <div class="products-add-to-cart">
                            <div class="input-counter">
                                <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                <input type="text" value="1" min="1">
                                <span class="plus-btn"><i class='bx bx-plus'></i></span>
                            </div>
                            <button type="submit" class="default-btn"><i class='bx bx-cart'></i> ADD TO CART</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End QuickView Modal Area -->

<!-- Start Sidebar Modal Area -->
<div class="productsFilterModal modal right fade" id="productsFilterModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="action_button d-flex">
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="bx bx-x"></i> Close</span>
                </button>
                <button type="button" class="close resetFilter" *ngIf="filterApplied" (click)="resetFilter()">Reset Filter
                </button>
            </div>
            <div class="modal-body">
                <aside class="widget-area p-0">
                    <!-- <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div> -->
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <!-- <li *ngFor="let item of itemByBrandCategory | keyvalue">
                                <a [ngClass]="{ 'active' : isValue == item.value.keys }" href="javascript:;" (click)="applyFilter($event, item.value.keys)" data-bs-dismiss="modal">{{ item.value.keys }}</a><span class="post_count">({{ item.value.val.length }})</span>
                            </li> -->
                            <li *ngFor="let item of filterCategoryLists">
                                <a [ngClass]="{ 'active' : isValue == item }" href="javascript:;" (click)="applyFilter($event, item, 'categories')" data-bs-dismiss="modal">{{ item }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Brands</h3>
                        <ul>
                            <li *ngFor="let brand of filterBrandLists"><a [ngClass]="{ 'active' : isValue == brand }" href="javascript:;" (click)="applyFilter($event, brand, 'brands')" data-bs-dismiss="modal">{{brand}}</a></li>
                        </ul>
                    </div>
                    <!-- <div class="widget widget_categories">
                        <h3 class="widget-title">Brands</h3>
                        <ul>
                            <li><a href="javascript:;" (click)="applyFilter('1')" data-bs-dismiss="modal">JHONSON</a><span class="post_count">(5)</span></li>
                            <li><a href="javascript:;" (click)="applyFilter('1')" data-bs-dismiss="modal">Havels</a><span class="post_count">(7)</span></li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_colors">
                        <h3 class="widget-title">Filter By Color</h3>
                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="gray">Gray</span>
                                    <span>Gray</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="green">Green</span>
                                    <span>Green</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="red">Red</span>
                                    <span>Red</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="black">Black</span>
                                    <span>Black</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="brown">Brown</span>
                                    <span>Brown</span>
                                </a>
                            </li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_price_filter">
                        <h3 class="widget-title">Filter by Price</h3>
                        <div class="collection_filter_by_price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055" name="filter_by_price" data-step="10">
                        </div>
                    </div> -->
                    <!-- <div class="widget widget_top_rated_products">
                        <h3 class="widget-title">Our Best Sellers</h3>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Long Sleeve Leopard</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Causal V-Neck Soft</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star-half'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Yidarton Women's Comfy</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Womens Tops Color</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                    </div> -->
                </aside>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal Area -->