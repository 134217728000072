<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url(../../../../assets/images/bg.jpg);">
    <h1 style="color:#fff">{{productData.productName}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{productData.productName}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Products Details Area -->
<section class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="products-details-image">
                    <!-- <div class="qrImage" *ngIf="showQrCode">
                        <qrcode  [qrdata]="qrData" [errorCorrectionLevel]="'M'" [width]="350"></qrcode>
                    </div> -->
                    <!-- <div class="slider_container" *ngIf="isMobile || isTablet">
                        <owl-carousel-o [options]="customOptions">
                            <ng-container>
                              <ng-template class="slide" carouselSlide [id]="productData.productID">
                                <img [src]="productData.imageURL" [alt]="productData.productName" [title]="productData.productName">
                              </ng-template>
                            </ng-container>
                        
                          </owl-carousel-o>  
                    </div> -->
                    <!-- <a href="assets/img/products/products4.jpg" class="popup-btn">
                        <img src="assets/img/products/products4.jpg" alt="image">
                    </a> -->
                    <div class="modal-viewer details" *ngIf="(glburl != '') && (isMobile || isTablet)">
                        <model-viewer id="my-model"
                            attr.src="{{glburl}}"
                            attr.ios-src="{{usdzUrl}}"
                            attr.poster="{{posterImageUrl}}"
                            alt="My model"
                            shadow-intensity="1"
                            environment-image="neutral"
                            reveal="manual"
                            camera-controls
                            auto-rotate ar
                            ar-scale="fixed"
                            ar-modes="webxr scene-viewer quick-look" crossorigin="anonymous">
                            <button slot="ar-button" id="ar-button">
                                View in your space
                              </button>
                      
                              <div id="ar-prompt">
                                <img src="https://cdn.glitch.global/66a9aadc-16d1-4ae0-b613-4d181051d3c1/ar_hand_prompt.png?v=1680846318449">
                              </div>
                      
                              <button id="ar-failure">
                                AR is not tracking!
                              </button>
                        </model-viewer>
                    </div>
                    <div class="modal-viewer details" *ngIf="(glburl != '') && !isMobile && !isTablet">
                        <model-viewer id="my-model"
                            attr.src="{{glburl}}"
                            attr.ios-src="{{usdzUrl}}"
                            attr.poster="{{posterImageUrl}}"
                            alt="My model"
                            shadow-intensity="1"
                            environment-image="neutral"
                            reveal="auto"
                            camera-controls
                            auto-rotate ar
                            ar-scale="fixed"
                            ar-modes="webxr scene-viewer quick-look" crossorigin="anonymous">
                            <button slot="ar-button" id="ar-button">
                                View in your space
                              </button>
                      
                              <div id="ar-prompt">
                                <img src="https://cdn.glitch.global/66a9aadc-16d1-4ae0-b613-4d181051d3c1/ar_hand_prompt.png?v=1680846318449">
                              </div>
                      
                              <button id="ar-failure">
                                AR is not tracking!
                              </button>
                        </model-viewer>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="products-details-desc">
                    <h3>{{productData.productName}}</h3>
                    <div class="price">
                        <span class="new-price">{{productData.productPrice | currency:"INR":"symbol"}}</span>
                        <!-- <span class="old-price">$210</span> -->
                    </div>
                    <p>{{productData.productDescription}}</p>
                    <div class="products-meta">
                        <!-- <span>SKU: <span class="sku">10</span></span> -->
                        <span><strong>Dimensions</strong> (L x W x H): &nbsp; <span class="in-stock"> {{productData.length}} x {{productData.width}} x {{productData.height}} {{productData.unit}}</span></span>
                        <!-- <span>Availability: <span class="in-stock">In Stock</span></span> -->
                        <!-- <span>Categories: <a routerLink="/">Chair</a></span> -->
                        <!-- <span>Tag: <a routerLink="/">Chair</a></span> -->
                    </div>
                    <div class="products-add-to-cart qr_container {{positionClass}}" *ngIf="!isMobile && !isTablet">
                        <!-- <div class="input-counter">
                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                            <input type="text" max="10" min="1" value="1">
                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                        </div> -->
                        <button type="button" class="default-btn px-5" data-bs-toggle="modal" data-bs-target="#qrModal" (click)="showQr($event, true)"> AR QR Code</button>
                        <!-- <div class="qr_button" *ngIf="showQrCode">
                            <qrcode [qrdata]="qrData" [errorCorrectionLevel]="'M'" [width]="150"></qrcode>
                        </div> -->
                    </div>
                    <div class="products-add-to-cart qr_container {{positionClass}}" *ngIf="isMobile || isTablet">
                        <!-- <div class="input-counter">
                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                            <input type="text" max="10" min="1" value="1">
                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                        </div> -->
                        <!-- <button type="button" class="default-btn px-5" (click)="viewAR($event, true, productData?.usdzURL)"> View in AR</button> -->
                        
                        <!-- <a type="button" class="btn default-btn px-5" [routerLink]="['../modal-viewer/' + productData?.productID]"> View in AR</a> -->
                    </div>
                    <!-- <a routerLink="/wishlist" class="add-to-wishlist-btn"><i class='bx bx-plus'></i> Add to Wishlist</a> -->
                    <!-- <div class="products-share">
                        <ul class="social">
                            <li><span>Share:</span></li>
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-12 col-md-12" >
                <div class="products-details-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Description</button>
                        </li>
                        <li class="nav-item" role="presentation" *ngIf="productData?.itemdetails?.length">
                            <button class="nav-link" id="additional-information-tab" data-bs-toggle="tab" data-bs-target="#additional-information" type="button" role="tab" aria-controls="additional-information" aria-selected="false">Additional Information</button>
                        </li>
                        <!-- <li class="nav-item" role="presentation">
                            <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews (2)</button>
                        </li> -->
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel">
                            <h3>{{productData.productName}}</h3>
                            <p>{{productData.productDescription}}</p>
                        </div>
                        <div class="tab-pane fade" id="additional-information" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr *ngFor="let item of productData?.itemdetails">
                                            <td>{{item.itemname}}</td>
                                            <td>{{item.itemvalue}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reviews" role="tabpanel">
                            <div class="products-review-comments">
                                <div class="user-review">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">James Anderson</span>
                                    </div>
                                    <span class="d-block sub-comment">Excellent</span>
                                    <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">Sarah Taylor</span>
                                    </div>
                                    <span class="d-block sub-comment">Video Quality!</span>
                                    <p>Was really easy to implement and they quickly answer my additional questions!</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">David Warner</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Coding!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user4.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">King Kong</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Video!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                            </div>
                            <div class="review-form-wrapper">
                                <h3>Add a review</h3>
                                <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="rating">
                                                <input type="radio" id="star5" name="rating" value="5" /><label for="star5"></label>
                                                <input type="radio" id="star4" name="rating" value="4" /><label for="star4"></label>
                                                <input type="radio" id="star3" name="rating" value="3" /><label for="star3"></label>
                                                <input type="radio" id="star2" name="rating" value="2" /><label for="star2"></label>
                                                <input type="radio" id="star1" name="rating" value="1" /><label for="star1"></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name *">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email *">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <p class="comment-form-cookies-consent">
                                                <input type="checkbox" id="test1">
                                                <label for="test1">Save my name, email, and website in this browser for the next time I comment.</label>
                                            </p>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit">SUBMIT</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Details Area -->
<!-- QR Modal -->
<div class="modal fade productsQuickView qrModal " id="qrModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>
            <div class="row align-items-center m-0">
                <div class="col-lg-12 col-md-12 qr_container">
                    <div class="products-image">
                        <qrcode [qrdata]="qrData" [errorCorrectionLevel]="'M'" [width]="314"></qrcode>
                    </div>
                </div>
                <div class="col-12 info_text">
                    <p >Scan the QR code with your mobile device to view the product in your space.</p>
                </div>
            </div>
        </div>
    </div>
</div>