import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { throwError } from 'rxjs';
import { ServiceService } from 'src/app/service.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss']
})
export class SimpleProductPageComponent implements OnInit {
    qrData:string = "";
    public showQrCode:boolean= false;
    public isMobile:boolean= false;
    public isTablet:boolean= false;
    public positionClass:string= 'top';
    public reveal:string= 'auto';
    public selectedBrand:string= 'auto';
    public timer:any;
    public productDetails:any;
    public productData:any;
    public posterImageUrl:any= '';
    public usdzUrl:any= '';
    public glburl:any= '';
    customOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        center: true,
        dots: true,
        autoHeight: true,
        autoWidth: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      };
    constructor(private service:ServiceService, private deviceService: DeviceDetectorService, private readonly http: HttpClient, private activatedRoute:ActivatedRoute, private router: Router) { 
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
    }

    ngOnInit(): void {
      this.service.itemByBrandCategory =[];
        // window.addEventListener('scroll', this.scroll, true);
        if(this.isMobile && this.isTablet)this.reveal = "manual";
        /**
         * resolver date to bind modal view
         */
        this.activatedRoute.data.subscribe((data) => {
          if(data){
            this.selectedBrand = data.data.brand;
            this.productData = data.data;
            this.productDetails = data.data;
            this.posterImageUrl = data.data.imageURL;
            this.usdzUrl = data.data.usdzURL;
            this.glburl = data.data.gltfURL;
            this.qrData = `${environment.appUrl}/${this.productData?.productID}`;
            // this.productData['itemdetails'] = [{itemname:'name', itemvalue:'value'}];
          }
        })
    }    
    
    scroll = (event): void => {
        // console.log(event);
      };

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Ergonomic Desk Sofa'
        }
    ]
    viewAR(event:any, flag:boolean=false, usdzURL){
      // this.router.navigate([`${usdzURL}`]);
    }
    showQr(event:any, flag:boolean=false){
        // (event.clientY > 300)?this.positionClass = 'top': this.positionClass = 'bottom';
        // this.showQrCode = flag;
        // if(this.timer)clearTimeout(this.timer)
        // this.timer = setTimeout(()=>{
        //     this.showQrCode = false;
        // }, 5000)
    }
    ngOnDestroy(){
      if(this.selectedBrand)this.service.applySelectedFilter(this.selectedBrand);
    }

}