import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    scroll(event, id) {
        // el.scrollIntoView({behavior: 'smooth'});
        // console.log(event);
        // console.log(id);
        
        // let el = document.getElementById(id);
        // el.scrollIntoView();
    }

}