<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url(../../../../assets/images/bg.jpg);">
    <h1 style="color:#fff"><strong>ARnxt </strong> <span style="font-weight: 500;"> Your own place</span></h1>
    <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> -->
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<section class="products-area ptb-100">
    <div class="container">
        <div class="fiwan-grid-sorting row align-items-center">
            <div class="col-lg-8 col-md-12 result-count">
                <div class="d-flex align-items-center">
                    <a href="#"  class="sidebar-filter" (click)="resetFiletr()" *ngIf="_filter" >
                        Reset Filter
                    </a>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#productsFilterModal" class="sidebar-filter"><i class='bx bx-filter'></i> Filter</a>
                    <p *ngIf="isMobile"><span class="count">{{filterdProductLists?.length}}</span> products</p>
                    <p *ngIf="!isMobile">Found <span class="count">{{filterdProductLists?.length}}</span> products</p>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <ngx-select-dropdown (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                </div>
            </div> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let Content of filterdProductLists | paginate: { itemsPerPage: 15, currentPage: shopFullWidth }">
                <div class="single-products-box product_box">
                    <div class="products-image">
                        <a style="cursor: pointer;" (click)="getProductDetails(Content.productID)" class="d-block">
                            <img [src]="Content.imageURL" alt="image">
                        </a>
                        <!-- <ul class="products-button">
                            <li><a routerLink="/wishlist"><i class='bx bx-heart'></i></a></li>
                            <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li>
                            <li><a href="{{Content.mainImg}}" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                            <li><a [routerLink]="[1]"><i class='bx bx-link'></i></a></li>
                        </ul> -->
                        <!-- <a [routerLink]="[Content.productID]" class="add-to-cart-btn" >View Details</a> -->
                        <a style="cursor: pointer;" class="add-to-cart-btn" (click)="getProductDetails(Content.productID)">View Details</a>
                    </div>
                    <div class="products-content">
                        <h3><a style="cursor: pointer;" (click)="getProductDetails(Content.productID)">{{Content.productName}}</a></h3>
                        <div class="price">
                            <span class="new-price">{{Content.productPrice | currency:"INR":"symbol"}}</span>
                            <!-- <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span> -->
                        </div>
                    </div>
                    <!-- <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span> -->
                    <!-- <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div> -->
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls (pageChange)="shopFullWidth = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->

<!-- Start QuickView Modal Area -->
<ng-container *ngIf="productDetails">
<div class="modal fade productsQuickView" id="productsQuickViewMobile" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="products-image">

                        <div class="modal-viewer" *ngIf="glburl != ''">
                            <model-viewer id="my-model"
                                attr.src="{{glburl}}"
                                attr.ios-src="{{usdzUrl}}"
                                attr.poster="{{posterImageUrl}}"
                                alt="My model"
                                shadow-intensity="1"
                                environment-image="neutral"
                                camera-controls
                                auto-rotate ar
                                ar-scale="fixed"
                                ar-modes="webxr scene-viewer quick-look" crossorigin="anonymous">
                                <button slot="ar-button" id="ar-button">
                                    View in your space
                                  </button>
                          
                                  <div id="ar-prompt">
                                    <img src="https://cdn.glitch.global/66a9aadc-16d1-4ae0-b613-4d181051d3c1/ar_hand_prompt.png?v=1680846318449">
                                  </div>
                          
                                  <button id="ar-failure">
                                    AR is not tracking!
                                  </button>
                            </model-viewer>
                        </div>
                        <!-- <div class="slider_container">
                            <owl-carousel-o [options]="customOptionsHome">
                                <ng-container>
                                  <ng-template class="slide" carouselSlide [id]="productDetails.productID">
                                    <img [src]="productDetails.imageURL" [alt]="productDetails.productName" [title]="productDetails.productName">
                                  </ng-template>
                                </ng-container>
                            
                              </owl-carousel-o>  
                        </div> -->
                        <!-- <img [src]="productDetails.imageURL" [alt]="productDetails.productName" [title]="productDetails.productName"> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="products-content">
                        <h3>{{productDetails.productName}}</h3>
                        <div class="price">
                            <span class="new-price">{{productDetails.productPrice | currency:"INR":"symbol"}}</span>
                        </div>
                        <p>{{productDetails.productDescription}}</p>
                        <div class="products-meta">
                            <span><strong>Dimensions</strong> (L x W x H): &nbsp; <span class="in-stock"> {{productDetails.length}} x {{productDetails.width}} x {{productDetails.height}} inches</span></span>
                            
                        </div>
                        <div class="products-add-to-cart">
                            <!-- <a type="button" class="btn default-btn px-5" data-bs-dismiss="modal" [routerLink]="['modal-viewer/' + productDetails?.productID]"> View in AR</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>
<!-- End QuickView Modal Area -->