import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  public httpOptions: any;
  public filterdProductLists: any;
  public type = new BehaviorSubject<any>('');
  public filterCategoryLists: any;
  public filter = new BehaviorSubject<any>('');
  public resetFilter = new BehaviorSubject<any>('');
  public loader = new BehaviorSubject<any>(false);
  public itemByBrandCategory: any = [];
  // public baseAPIUrl:string = 'https://3ef9gn5kk2.execute-api.ap-south-1.amazonaws.com/arnxt_prod/webar/';//product_list'
  // public baseAPIUrl2 = 'https://3ef9gn5kk2.execute-api.ap-south-1.amazonaws.com/arnxt_prod/webar/product_details?productID=2'
  constructor(private http : HttpClient) { }
/**
 * set header 
 */
  setHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    this.httpOptions = { headers: headers };
  }
  /**
   * getData use to get data 
   * @param url 
   * @returns 
   */
  getData(url: any): Observable<any> {
    this.setHeader();
    return this.http.get(environment.baseAPIUrl + url, this.httpOptions);
  } 
  /**
   * postFilterData function to get filterd items records
   * @param url 
   * @param body 
   * @returns 
   */
  postFilterData(url: any, body:any) {
    this.setHeader();
    return this.http.post(environment.baseAPIUrl + url, body, this.httpOptions);
  }
  /**
   * applySelectedFilter function use to reset selected filter
   * @param item 
   */
  applySelectedFilter(item:any){
    this.filter.next('reset')
    // setTimeout(()=>{
    //   this.filter.next(item)
    // }, 500)
  }
}
