<div class="modal-viewer" *ngIf="glburl != ''">
    <model-viewer id="my-model"
        attr.src="{{glburl}}"
        attr.ios-src="{{usdzUrl}}"
        attr.poster="{{posterImageUrl}}"
        alt="My model"
        shadow-intensity="1"
        environment-image="neutral"
        camera-controls
        auto-rotate ar
        ar-scale="fixed"
        ar-modes="webxr scene-viewer quick-look" crossorigin="anonymous">
        <button slot="ar-button" id="ar-button">
            View in your space
          </button>
  
          <div id="ar-prompt">
            <img src="https://cdn.glitch.global/66a9aadc-16d1-4ae0-b613-4d181051d3c1/ar_hand_prompt.png?v=1680846318449">
          </div>
  
          <button id="ar-failure">
            AR is not tracking!
          </button>
    </model-viewer>
</div>